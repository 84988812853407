import React from "react";
import { Row, Col, Typography } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import CtaSecondary from "../../components/Buttons/secondary-cta";
import ogImage from "../../images/global_assets/og-image.png";
import LegalStyles from "../../components/Layout/LegalStyles";

const { Title } = Typography;

const WebsiteService = () => {
  return (
    <Layout className="consumer-privacy">
      <SEO
        title="SpotOn | Privacy &amp; Legal"
        description="At SpotOn, we respect your privacy and are committed to being upfront and transparent. Please review our terms of services and privacy policies for more information."
        image={`https://spoton.com/${ogImage}`}
      />
      <div style={{ marginTop: 25 }}>
        <CtaSecondary target="/legal" ctaTitle="Legal" reverseIcon />
      </div>
      <main className="consumer-privacy__data">
        <Row>
          <Col lg={16} sm={24}>
            <div>
              <Title level={5}>EFFECTIVE AS OF December 16th, 2019.</Title>
              <Title level={1}>SpotOn Website Service Description</Title>
              <Title id="info" level={2}>
                Information SpotOn Collects from you.
              </Title>
              <Title level={3}>Website Creation Terms</Title>
              <p>
                SpotOn Website is a custom website building service for small
                business owners. When you request a website from SpotOn, our
                professional design team will create a website for you based on
                information that we gather about your business over the course
                of phone and email communications.
              </p>
              <p>
                Included in the website build service fee are a maximum of six
                (6) custom-designed pages. If you require additional pages, we
                can build them for you at an additional charge of $50 per page.
                Alternatively you will also have access to our easy-to-use
                website builder where you can create pages on your own at no
                cost.
              </p>
              <p>
                During the website build process, our team will be in close
                communication with you to gather the information you wish to be
                included on your website. We will create a first draft of your
                website for you to review. After the first draft is completed,
                included at no cost are two (2) revision cycles where you are
                able to request changes/additions to your site before it goes
                live. If you require more than two revisions, we offer
                additional revision cycles for a cost of $75/each.
              </p>
              <Title level={3}>Website Domain Name Terms</Title>
              <p>
                Included with your SpotOn website is a free custom domain name
                and SSL certificate. If you require a custom domain name for
                your website, we will register the domain name on your behalf
                and connect it properly to your website using a secure SSL
                certificate at no cost. If you already have a domain that you
                would like to use, we will assist you in connecting it to your
                new website built by SpotOn.
              </p>
              <Title level={3}>Website Modification Terms</Title>
              <p>
                Once the SpotOn website team has published your website, we will
                continue to provide unlimited phone support at no cost. You will
                have access to our website builder platform which will allow you
                to make changes and updates on your own. If you require custom
                changes from the SpotOn website team after your website is live,
                we will kindly make up to one (1) custom update to your website
                per month at no cost. If you require extensive changes beyond
                that, you will incur a $75 fee per additional change.
              </p>
              <Title id="disclosure" level={3}>
                Standard Website Fees with Integrated SpotOn Processing
              </Title>
              <p>
                By agreeing to have SpotOn build you a website as an add-on to
                your credit card processing relationship with SpotOn, you will
                be charged $65 each month for website services. We will waive an
                initial $295 design fee which will only become due if you decide
                to cancel credit card processing with SpotOn.
              </p>
              <p>
                The $65 per month fee includes the initial website design,
                content creation, as well as costs associated with domain name
                registration and a SSL certificate (if applicable). The first
                $65 charge will take place when you agree to have SpotOn start
                the website build process. Your account will automatically be
                charged every month for $65 as long as you have a website build
                in-progress or have a live website with SpotOn.
              </p>
              <p>
                If you wish to cancel your website with SpotOn, your can request
                cancellation at any time by emailing website@spoton.com or
                calling us during regular business hours at 415-306-9522 anytime
                Monday-Friday between 9am and 6pm EST. If you have an existing
                domain name with SpotOn we will allow you to transfer it to a
                new registrar at no additional cost upon request.
              </p>
              <p>
                Please note that if you cancel your processing relationship with
                SpotOn, your account will be billed the $295 website design fee.
                An increased website service fee of $75/month instead of the
                original $65/month will apply if you wish to keep your website
                without SpotOn credit card processing.{" "}
              </p>
              <Title level={3}>
                Standard Website Fees with SpotOn Website as a Stand-Alone
                Service
              </Title>
              <p>
                By agreeing to have SpotOn build you a website, SpotOn will
                charge you $45 each month which covers the initial website
                design, content creation, as well as costs associated with
                domain name registration and a SSL certificate (if applicable).
              </p>
              <p>
                The first $45 charge will take place when you agree to have
                SpotOn start the website build process. Your credit card will
                automatically be charged every month for $45 as long as you have
                a website build in progress or have a live website with SpotOn.
              </p>
              <p>
                If you wish to cancel your website with SpotOn, your can request
                cancellation at any time by emailing website@spoton.com or
                calling us during regular business hours at 415-306-9522 anytime
                Monday-Friday between 9am and 6pm EST. If you have an existing
                domain name with SpotOn we will allow you to transfer it to a
                new registrar at no additional cost upon request.
              </p>
              <Row>
                <Col>
                  <Title id="contact" level={2}>
                    Contacting SpotOn
                  </Title>
                  <p>
                    SpotOn Transact, LLC <br /> 100 California St, 9th Floor{" "}
                    <br /> San Francisco, CA 94111 <br /> 24/7 Phone:
                    <a href="tel:+415-306-9522">&nbsp; 415-306-9522</a>
                    <br /> Email:
                    <a href="website@spoton.com"> &nbsp; website@spoton.com</a>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={8} sm={24}>
            <div className="aside">
              <Title level={3}>Related Documents</Title>
              <CtaSecondary
                target="/legal/consumer-privacy"
                ctaTitle="Consumer Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-privacy"
                ctaTitle="Merchant Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-terms"
                ctaTitle="Merchant Terms"
              />
              <CtaSecondary
                target="/payments-terms.pdf"
                ctaTitle="Payments Terms"
              />
              <CtaSecondary target="/legal/user-terms" ctaTitle="User Terms" />
              <CtaSecondary
                target="/legal/point-of-sale-terms"
                ctaTitle="Point-of-Sale Terms"
              />
              <CtaSecondary
                target="/legal/reserve-terms"
                ctaTitle=" Reserve Terms"
              />
               <CtaSecondary
                target="/legal/teamwork-terms"
                ctaTitle="SpotOn Teamwork Terms"
              />
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default WebsiteService;
